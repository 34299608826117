import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Card } from "reactstrap";
import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import Loader from "../components/loader/loader";
import Transactioncashfree from "../components/transaction/transactioncashfree";
import Transactioncash from "../components/transaction/transactioncash";
import Forbiden from "../components/forbidden";

export default function Cashfree() {
  let cashfree;
  const [token] = useState(useParams().id);
  var apiurl = "https://device.onepebeta.com";
  const [isDataset, setIsDataset] = useState(false);
  const [isSessionloaded, setIsSessionloaded] = useState(false);
  const [pagename, setPagename] = useState("loader");
  const [paymentdetails, setPaymentdetails] = useState();
  const [color, setColor] = useState("#000000");
  const [data, setData] = useState();
  useEffect(() => {
    initatepayment();
  }, []);
  var initializeSDK = async function () {
    cashfree = await load({
      mode: "production",
    });
  };
  initializeSDK();

  const doPayment = async () => {
    let checkoutOptions = {
      paymentSessionId: paymentdetails.payment_session_id,
      redirectTarget: "_self",
    };
    cashfree.checkout(checkoutOptions);
  };
  function initatepayment() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/v1/webpayment/initiatecashfree`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.status_code == 200) {
          if (response.data.data.order_status == 3) {
            setIsDataset(true);
            if (response.data.data.order_id == null) {
              setData(response.data.data);
              getsessiondetails(response.data.data);
              setPagename("cashfree");
            } else {
              setData(response.data.data);
              getoldsessiondetails(response.data.data);
              setPagename("cashfree");
            }
          } else {
            if (response.data.data.payment_mode == "cash") {
              setIsDataset(true);
              setData(response.data.data);
              setPagename("cash");
            } else {
              setIsDataset(true);
              setData(response.data.data);
              setPagename("transaction");
            }
          }
        }
        console.log(response.data.data);
      })
      .catch((error) => {
        let errorresponse = JSON.parse(error.request.response);
        if (errorresponse.code == 403) {
          setPagename("forbiden");
        }
      });
  }
  function getsessiondetails(billdata) {
    let formdata = {
      bill_id: billdata.bill_id,
      bill_type: 1,
      device_id: billdata.device,
      user_id: billdata.user_id,
      link: "https://payment.onepebeta.com/" + token,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://test.onepebeta.com/v1/cashfree/webcheckout`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formdata,
    };

    axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.status == 200) {
          setPaymentdetails(response.data.data);
          setIsSessionloaded(true);
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getoldsessiondetails() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/v1/webpayment/getoldsessiondetails`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line
        setPaymentdetails(response.data.data);
        setIsSessionloaded(true);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {pagename == "loader" && <Loader />}
      {pagename == "cashfree" && (
        <div className="p-2">
          <Container>
            <Row className="mt-5 mb-5">
              <Col md={12} lg={12} xl={12}>
                <div className="d-flex flex-column justify-content-between mt-4">
                  <Card
                    className="p-4 mb-5"
                    style={{
                      borderRadius: "75px",
                      border: "1.241px solid " + color,
                      borderTop: "none",
                      boxShadow:
                        "0px 10.05263px 71.80451px 0px rgba(0, 0, 0, 0.16)",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      {isDataset == true && (
                        <div
                          className="d-flex flex-column align-items-center p-3 mb-4"
                          style={{
                            width: "fit-content",
                            boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.07)",
                            borderRadius: "50%",
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            marginTop: "-4.5rem",
                          }}
                        >
                          <img src={data.logo} width="100px" alt="logo"></img>
                        </div>
                      )}
                    </div>
                    <h3
                      className="pt-2 pb-4"
                      align="center"
                      style={{ fontWeight: 800 }}
                    >
                      {isDataset == true && data.store}
                    </h3>
                    <div>
                      <p align="center" className="webparagraph">
                        Amount to be paid
                      </p>
                      <h1 align="center" className="amount pb-5">
                        {isDataset == true && `₹ ${data.amount}`}
                      </h1>
                    </div>
                    <div className="row p-0">
                      <div className="d-flex flex-column align-items-center">
                        {isSessionloaded == true && (
                          <button
                            type="submit"
                            className="btn btn-success mb-3"
                            id="renderBtn"
                            onClick={doPayment}
                            style={{
                              backgroundImage: "linear-gradient(45deg, #28419a, #0592ec, #8ac164)",
                              border:"none"
                            }}
                          >
                            Proceed to Pay
                          </button>
                        )}
                        <p className="text-danger pb-4" align="center">
                          * Please do not refresh this page *
                        </p>
                      </div>
                      <p align="center">
                        <small> Powered by</small>
                        <img
                          src="https://www.onepeapp.com/images/OnePe_Final-01.svg"
                          width="80px"
                          alt="logo"
                        ></img>
                      </p>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {pagename == "transaction" && <Transactioncashfree data={data} />}
      {pagename == "cash" && <Transactioncash amount={data.amount} />}
      {pagename == "forbiden" && <Forbiden />}
    </div>
  );
}
