import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import "../transaction.css";
var FA = require("react-fontawesome");

export default function Transactioncash(props) {
  var amount=props.amount;
  const goback = () => {
    window.location.href = "https://wa.me/918085666966";
  };
  return (
    <Container>
        <div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              className="btn-shadow mb-5"
              onClick={goback}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50px",
                backgroundColor: "white",
              }}
              color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </Button>
          </div>
          <Card
            className="p-4 mb-5"
            style={{
              borderRadius: "75px",
              border: "1.241px solid ",
              borderTop: "none",
              boxShadow: "0px 10.05263px 71.80451px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <CardBody>
              <div className="d-flex align-items-center justify-content-center">
                  <Button
                    className="btn-shadow mb-5"
                    onClick={goback}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                      color: "white",
                      background:
                        "linear-gradient(132deg, #00D455 -53.23%, #56B7E4 109.78%)",
                      filter:
                        "drop-shadow(0px 4px 71px rgba(87, 226, 221, 0.46))",
                      border: "0px",
                      marginTop: "-5.5em",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="3em"
                      fill="white"
                      viewBox="0 0 448 512"
                    >
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </Button>
              
              
              </div>{" "}
              <h3 align="center" style={{ fontSize: "20px" }}>
                  Cash Payment
                </h3>
              <h2
                className="mb-5"
                align="center"
                style={{ color: "#2F9A95", fontSize: "35px" }}
              >
              <FA name="inr" />
                {" "+amount}
              </h2>
            
              <p align="center">Payment for this bill has been done by cash</p>
              
            </CardBody>
          </Card>
          
        </div>
      
    </Container>
  );
}
