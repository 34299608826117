import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import "./errorpage.css";

export default function Errorpage() {
  const goback = () => {
    window.location.href = "https://wa.me/918085666966";
  };
  return (
    <Row className="fullpage d-flex align-items-center m-3">
      <Col md={12}>
        <Card color="danger" outline className="errorcard p-3">
          <div className="d-flex justify-content-center">
            {" "}
            <Button
              className="btn-shadow mb-5"
              onClick={goback}
              style={{
                width: "50px",
                height: "50px",
                marginTop: "-40px",
                borderRadius: "50px",
                marginRight: "15px",
                backgroundColor: "white",
              }}
              color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </Button>
          </div>

          <CardBody>
            <h1 className="errorcode" align="center">
              403
            </h1>
            <p className="mb-5" align="center">
              Forbidden
            </p>
            <p className="errorsmall" align="center">
              The payment link may expired
            </p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
