import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import axios from "axios";
import Moment from "react-moment";
import Loading from "../loader/loader";
import { useParams } from "react-router-dom";
import "../transaction.css";
var FA = require("react-fontawesome");

export default function Transactioncashfree(props) {
  var billdata = props.data;
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000000");
  const [length,setLength] = useState(0);
  const [token] = useState(useParams().id);
  useEffect(() => {
    gettransactiondetails(billdata);
  }, []);
  function gettransactiondetails(billdata) {
    let formdata = {
      "bill_id": billdata.bill_id,
      "bill_type": 1,
      "device_id": billdata.device,
      "user_id":billdata.user_id,
      "order_id":billdata.order_id
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://test.onepebeta.com/v1/cashfree/orderstatus`,
      data : formdata
    };

    axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line
        let noofresponse = 0;
        if (response.data[noofresponse].payment_status == "SUCCESS") {
          setResponse(response.data);
          setColor("#56B7E4")
          setLoading(true);
        }else if (response.data[noofresponse].payment_status == "FAILED") {
          setResponse(response.data);
          setColor("#F90000")
          setLoading(true);
        }else if (response.data[noofresponse].payment_status == "PENDING") {
          setResponse(response.data);
          setColor("#56B7E4")
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const goback = () => {
    window.location.href = "https://wa.me/918085666966";
  };
  const retry = ()=>{
    getsessiondetails(billdata);
  }
  function getsessiondetails(billdata) {
    let formdata = {
      bill_id: billdata.bill_id,
      bill_type: 1,
      device_id: billdata.device,
      user_id: billdata.user_id,
      link: "https://payment.onepeapp.com/" + token,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://test.onepebeta.com/v1/cashfree/webcheckout`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formdata,
    };

    axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line
        if (response.data.status == 200) {
          window.location.reload();
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <Container>
      {loading === true ? (
        <div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              className="btn-shadow mb-5"
              onClick={goback}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50px",
                backgroundColor: "white",
              }}
              color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </Button>
          </div>
          <Card
            className="p-4 mb-5"
            style={{
              borderRadius: "75px",
              border: "1.241px solid " + color,
              borderTop: "none",
              boxShadow: "0px 10.05263px 71.80451px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <CardBody>
              <div className="d-flex align-items-center justify-content-center">
                {response[length].payment_status == "SUCCESS" && (
                  <Button
                    className="btn-shadow mb-5"
                    onClick={goback}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                      color: "white",
                      background:
                        "linear-gradient(132deg, #00D455 -53.23%, #56B7E4 109.78%)",
                      filter:
                        "drop-shadow(0px 4px 71px rgba(87, 226, 221, 0.46))",
                      border: "0px",
                      marginTop: "-5.5em",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="3em"
                      fill="white"
                      viewBox="0 0 448 512"
                    >
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg>
                  </Button>
                )}
                {response[length].payment_status == "FAILED" && (
                  <Button
                    className="btn-shadow mb-5"
                    onClick={goback}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                      color: "white",
                      background:
                        "linear-gradient(132deg, #F90000 -53.23%, #FF4040 109.78%)",
                      filter:
                        "drop-shadow(0px 4px 71px rgba(253, 34, 34, 0.49))",
                      border: "0px",
                      marginTop: "-5.5em",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 80 80"
                      fill="none"
                    >
                      <path
                        d="M60.0776 20.2349L20.2344 60.0781"
                        stroke="white"
                        stroke-width="6.64054"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.2344 20.2349L60.0776 60.0781"
                        stroke="white"
                        stroke-width="6.64054"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                )}
                {response[length].payment_status == "PENDING" && (
                  <Button
                    className="btn-shadow mb-5"
                    onClick={goback}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                      color: "white",
                      background:
                        "linear-gradient(132deg, #F99500 -53.23%, #E4DF56 109.78%)",
                      filter:
                        "drop-shadow(0px 4px 71px rgba(239, 185, 42, 0.54))",
                      border: "0px",
                      marginTop: "-5.5em",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      viewBox="0 0 77 77"
                      fill="none"
                    >
                      <path
                        d="M38.576 70.0793C55.9395 70.0793 70.0153 56.0034 70.0153 38.64C70.0153 21.2765 55.9395 7.20068 38.576 7.20068C21.2126 7.20068 7.13672 21.2765 7.13672 38.64C7.13672 56.0034 21.2126 70.0793 38.576 70.0793Z"
                        stroke="white"
                        stroke-width="5.55428"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M38.5752 19.7764V38.6399L51.1509 44.9278"
                        stroke="white"
                        stroke-width="5.55428"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                )}
              </div>{" "}
              {response[length].payment_status == "SUCCESS" && (
                <h3 align="center" style={{ fontSize: "20px" }}>
                  Transaction Successful
                </h3>
              )}
              {response[length].payment_status == "FAILED" && (
                <h3 align="center" style={{ fontSize: "20px" }}>
                  Transaction Failed
                </h3>
              )}
              {response[length].payment_status == "PENDING" && (
                <h3 align="center" style={{ fontSize: "20px" }}>
                  Transaction Pending
                </h3>
              )}
              <h2
                className="mb-5"
                align="center"
                style={{ color: "#2F9A95", fontSize: "35px" }}
              >
                {response[1].store_name}
              </h2>
              <p>
                <span className="text-muted">Transaction ID</span> <br></br>
                <b>
                  <small>{response[length].bank_reference}</small>
                </b>
              </p>
              <p>
                <span className="text-muted">Amount</span> <br></br>
                <b>
                  <small>
                    <FA name="inr" /> {response[length].order_amount}
                  </small>
                </b>
              </p>
              <p>
                <span className="text-muted">Date</span> <br></br>
                <b>
                  <small>
                    <Moment format="DD-MM-YYYY hh:mm:ss A">
                      {response[length].payment_time}
                    </Moment>
                  </small>
                </b>
              </p>
              <p>
                <span className="text-muted">Payment Mode</span> <br></br>
                <b>
                  <small>{response[length].payment_group.toUpperCase()}</small>
                </b>
              </p>
              {response[length].payment_status == "FAILED" &&
              <Button color="danger" onClick={retry}>Retry</Button>}
            </CardBody>
          </Card>
          <p align="center">
            <small> Powered by</small>
            <img
              src="https://www.onepeapp.com/images/OnePe_Final-01.svg"
              width="80px"
              alt="logo"
            ></img>
          </p>
        </div>
      ) : (
        <Loading />
      )}
    </Container>
  );
}
