import React from "react";
import "./loader.css";

export default function Loader() {
  return (
    <div>
      <div className="payment-loader">
        <div className="pad">
          <div className="chip"></div>
          <div className="line line1"></div>
          <div className="line line2"></div>
        </div>
        <div className="loader-text">Loading payment information...</div>
      </div>
    </div>
  );
}
