// src/App.js
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./page/home";
import Forbiden from "./components/forbidden";
import Cashfree from "./page/cashfree";

function App() {
  
  const router = createBrowserRouter([
    // {
    //   path: "/:id",
    //   element: <Home />,
    // },
    {
      path: "/",
      element: <Forbiden/>,
    },
    {
      path: "/:id",
      element: <Cashfree />,
    },
  ]);

  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
